import React from "react"
import { Link, graphql } from "gatsby"
import { useWindowSize } from "../util"
import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import styled from "styled-components"
import { theme } from "../theme"
import Carousel from "react-multi-carousel"
import { Dialog } from "@reach/dialog"

const Title = styled.h1`
  font-size: ${({ theme }) => theme.fontSizes[6]};
  font-family: ${({ theme }) => theme.fonts.heading};
  color: ${({ theme }) => theme.colors.text.normal};
  margin-bottom: ${({ theme }) => theme.space[4]};
  line-height: 1.1;

  @media (max-width: 768px) {
    font-size: ${({ theme }) => theme.fontSizes[5]};
  }
`

const Subtitle = styled.h1`
  font-size: ${({ theme }) => theme.fontSizes[2]};
  font-family: ${({ theme }) => theme.fonts.text};
`

const SectionTitle = styled.h1`
  font-size: ${({ theme }) => theme.fontSizes[5]};
  font-family: ${({ theme }) => theme.fonts.heading};
  color: ${({ theme }) => theme.colors.text.normal};
  margin-bottom: ${({ theme }) => theme.space[4]};
  line-height: 1.2;
`

const SectionAlternateTitle = styled.h1`
  font-size: ${({ theme }) => theme.fontSizes[2]};
  font-family: ${({ theme }) => theme.fonts.text};
  font-weight: 700;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.text.normal};
  margin-bottom: ${({ theme }) => theme.space[4]};
  line-height: 1.2;
`

const HeroContainer = styled.div`
  height: 580px;
  overflow: hidden;
  position: relative;
`

const Img = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`

const TestimonialImg = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;

  @media (max-width: 768px) {
    max-height: 300px;
  }
`

const BioImgContainer = styled.div`
  height: 380px;
  overflow: hidden;
  position: relative;
`

const SquareImgContainer = styled.div`
  height: 380px;
  overflow: hidden;
  position: relative;
`

const SquareImgOverlay = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.fontSizes[4]};
  background: rgba(0, 0, 0, 0.3);
  transition: filter 0.2s ease-out, -webkit-filter 0.2s ease-out;

  &:hover&:after {
    display: block;
    position: absolute;
    border: 2px solid ${({ theme }) => theme.colors.white};
    top: 10px;
    left: 10px;
    height: calc(100% - 20px);
    width: calc(100% - 20px);
    content: " ";
    opacity: 1;
    pointer-events: none;
    transition: opacity 0.35s ease-in;
  }
`

const BioImgOverlay = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 25px 35px;
  background: linear-gradient(180deg,
  transparent,
      rgba(255,251,242));
      position: absolute;
      bottom: 0;
      height: 120px;
      width: 100%;
  }
`

const TestimonialTitle = styled.div`
  font-size: ${({ theme }) => theme.fontSizes[4]};
  font-family: ${({ theme }) => theme.fonts.heading};
  font-weight: 700;
  margin-bottom: 20px;
`

const TestimonialText = styled.div`
  font-size: ${({ theme }) => theme.fontSizes[2]};
  font-family: ${({ theme }) => theme.fonts.text};
  margin-bottom: 20px;
`

const TestimonialAuthor = styled.div`
  font-size: ${({ theme }) => theme.fontSizes[2]};
  font-family: ${({ theme }) => theme.fonts.text};
  text-transform: italic;
`

const ImgFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 25px 35px;
  background: linear-gradient(180deg,
  transparent,
      rgba(0,0,0,.55));
      position: absolute;
      bottom: 0;
      height: 120px;
      width: 100%;
  }
`

const ImgFooterText = styled.div`
  font-size: ${({ theme }) => theme.fontSizes[3]};
  font-family: ${({ theme }) => theme.fonts.text};
  color: ${({ theme }) => theme.colors.white};
  line-height: 23px;
`

const ImgFooterSubtext = styled.div`
  font-size: 13px;
  font-family: ${({ theme }) => theme.fonts.text};
  color: ${({ theme }) => theme.colors.white};
  line-height: 19px;
`

const TextRight = styled.div`
  text-align: right;
`

const StampContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  z-index: 1;
`

const Stamp = styled.div`
  top: 20px;
  left: -60px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px solid #f65b40;
  border-radius: 50%;
  height: 90px;
  width: 90px;
  position: absolute;
  z-index: 10;
  transform: matrix(0.94, -0.34, 0.34, 0.94, 0, 0);
`

const StampText = styled.div`
  color: ${({ theme }) => theme.colors.main[1]};
  font-family: ${({ theme }) => theme.fonts.text};
  font-size: 11px;
  font-weight: 700;
  line-height: 17px;
  text-align: center;
  text-transform: uppercase;
  width: 70%;
`

const MobileOnlyContainer = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: flex;
  }
`

const HomePage = ({ data }) => {
  const imgPath = new URL(`${data.wordpressPage.acf.main_image.image_link.url}`)
  const [width] = useWindowSize()

  return (
    <Layout>
      <SEO
        title="Home"
        description={
          data.wordpressPage.acf.seo_description
            ? data.wordpressPage.acf.seo_description
            : "Nikhil Bhanwra, nikhilbhanwra.com, Judy Marsales, Hamilton, Ontario, Burlington, Real Estate"
        }
      />
      <MobileOnlyContainer>
        <BioImgContainer style={{ height: "auto" }}>
          <Img src={data.wordpressPage.acf.bio.image.source_url} />
        </BioImgContainer>
      </MobileOnlyContainer>
      <section className="section">
        <div className="columns is-vcentered">
          <div className="column is-two-fifths">
            <div
              style={{
                maxWidth: 450,
                margin: `20px auto`,
              }}
            >
              <Title>{data.wordpressPage.acf.main_text.title}</Title>
              <Subtitle>{data.wordpressPage.acf.main_text.subtitle}</Subtitle>
              <br />
              {data.wordpressPage.acf.main_button && (
                <Link
                  to={data.wordpressPage.acf.main_button.url}
                  style={{ color: theme.colors.white }}
                >
                  <button
                    className="button"
                    style={{
                      padding: `10px 40px`,
                      fontSize: `${theme.fontSizes[3]}`,
                    }}
                  >
                    {data.wordpressPage.acf.main_button.title}
                  </button>
                </Link>
              )}
            </div>
          </div>
          <div className="column is-three-fifths">
            <StampContainer>
              <Stamp>
                <StampText>
                  {data.wordpressPage.acf.main_image.stamp_text}
                </StampText>
              </Stamp>
            </StampContainer>
            <HeroContainer style={{ height: width > 768 ? 580 : 300 }}>
              <Link to={imgPath.pathname}>
                <Img src={data.wordpressPage.acf.main_image.image.source_url} />
                <ImgFooter>
                  <div>
                    <ImgFooterText>
                      {data.wordpressPage.acf.main_image.left_top_text}
                    </ImgFooterText>
                    <ImgFooterSubtext>
                      {data.wordpressPage.acf.main_image.left_bottom_text}
                    </ImgFooterSubtext>
                  </div>
                  {width > 1000 && (
                    <TextRight>
                      <ImgFooterText>
                        {data.wordpressPage.acf.main_image.right_top_text}
                      </ImgFooterText>
                      <ImgFooterSubtext>
                        {data.wordpressPage.acf.main_image.right_bottom_text}
                      </ImgFooterSubtext>
                    </TextRight>
                  )}
                </ImgFooter>
              </Link>
            </HeroContainer>
          </div>
        </div>
      </section>
      <section
        className="section is-medium"
        style={{ background: theme.colors.secondary[2] }}
      >
        <div className="container" style={{ maxWidth: 1064 }}>
          <div className="columns is-vcentered">
            <div className="column is-half">
              <SectionTitle>{data.wordpressPage.acf.bio.title}</SectionTitle>
              <p>{data.wordpressPage.acf.bio.description}</p>
              <br />
              {/* TODO: FIX hardcode*/}
              <Link
                to={data.wordpressPage.acf.bio.link_to_bio.url}
                style={{ color: theme.colors.white }}
              >
                <button
                  className="button"
                  style={{
                    padding: `10px 40px`,
                    fontSize: `${theme.fontSizes[3]}`,
                  }}
                >
                  {data.wordpressPage.acf.bio.link_to_bio.title}
                </button>
              </Link>
            </div>
            {width >= 768 && (
              <div className="column is-half">
                <BioImgContainer>
                  <Img src={data.wordpressPage.acf.bio.image.source_url} />
                </BioImgContainer>
              </div>
            )}
          </div>
        </div>
      </section>
      <section className="section is-medium">
        <div className="container" style={{ maxWidth: 1064 }}>
          <SectionAlternateTitle>Houses</SectionAlternateTitle>
          <div className="columns is-multiline is-variable is-4">
            {data.allWordpressWpListing.nodes.map((item, i) => (
              <div className="column is-half" key={i}>
                <Link to={`/listing/${item.slug}`}>
                  <SquareImgContainer>
                    <Img src={item.acf.main_image.source_url} />
                    <SquareImgOverlay>
                      <h2>{item.acf.price}</h2>
                      <p>{item.acf.listing_address}</p>
                    </SquareImgOverlay>
                  </SquareImgContainer>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section
        className="section is-medium"
        style={{ background: theme.colors.secondary[3], height: "auto" }}
      >
        <div className="container testimonials" style={{ maxWidth: 1264 }}>
          <Carousel
            additionalTransfrom={0}
            arrows
            centerMode={false}
            className=""
            containerClassName="max-container"
            autoPlay={true}
            customTransition="transform 700ms ease-in-out"
            autoPlaySpeed={15000}
            dotListClassName=""
            draggable
            focusOnSelect={false}
            infinite={false}
            itemClassName="carousel-item-padding-40-px"
            keyBoardControl
            minimumTouchDrag={20}
            responsive={{
              desktop: {
                breakpoint: {
                  max: 2000,
                  min: 1024,
                },
                items: 1,
                partialVisibilityGutter: 40,
              },
              mobile: {
                breakpoint: {
                  max: 464,
                  min: 0,
                },
                items: 1,
                partialVisibilityGutter: 30,
              },
              tablet: {
                breakpoint: {
                  max: 1024,
                  min: 464,
                },
                items: 1,
                partialVisibilityGutter: 60,
              },
            }}
            showDots={false}
            slidesToSlide={1}
            swipeable
          >
            {data.allWordpressWpTestimonial.nodes.map(item => (
              <div
                className="container"
                style={{ maxWidth: 1064, padding: 20 }}
              >
                <div className="columns is-vcentered is-variable is-8">
                  {item.acf.image && (
                    <div className="column is-two-fifths">
                      <TestimonialImg
                        src={item.acf.image.source_url}
                        style={{ borderRadius: 10 }}
                      />
                    </div>
                  )}
                  <div className="column is-three-fifths">
                    <TestimonialText>
                      <Img
                        style={{ width: 30 }}
                        src="/images/quotation-mark.svg"
                      />{" "}
                      <span
                        dangerouslySetInnerHTML={{ __html: item.acf.body }}
                      />
                    </TestimonialText>
                    <TestimonialAuthor> - {item.acf.author}</TestimonialAuthor>
                  </div>
                </div>
              </div>
            ))}
          </Carousel>
        </div>
      </section>
    </Layout>
  )
}

export default HomePage

export const query = graphql`
  query($id: Int!) {
    wordpressPage(wordpress_id: { eq: $id }) {
      id
      wordpress_id
      slug
      acf {
        main_text {
          title
          subtitle
        }
        bio {
          title
          description
          image {
            source_url
          }
          link_to_bio {
            url
            title
          }
        }
        main_image {
          image {
            source_url
          }
          image_link {
            url
          }
          stamp_text
          left_top_text
          left_bottom_text
          right_top_text
          right_bottom_text
        }
        main_button {
          url
          title
        }
        seo_description
      }
    }
    allWordpressWpListing(sort: { fields: date, order: DESC }) {
      nodes {
        id
        acf {
          listing_address
          listing_city
          previous_price
          price
          action_button
          sidebar_details
          main_image {
            source_url
            title
            caption
          }
        }
        title
        content
        slug
        wordpress_id
      }
    }
    allWordpressWpTestimonial {
      nodes {
        acf {
          author
          body
          date
          image {
            source_url
          }
        }
      }
    }
  }
`
